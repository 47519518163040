/* eslint-disable */
import * as React from 'react';
export interface NavArrowRightProps extends React.SVGAttributes<SVGElement> {
  size?: string;
}
export const NavArrowRight: React.SFC<NavArrowRightProps> = ({size, className, ...props}) => (
  <svg
    width="12px"
    height="24px"
    viewBox="0 0 12 24"
    version="1.1"
    style={{pointerEvents: 'none'}}
    xmlns="http://www.w3.org/2000/svg">
    <title>09 Icons / Arrows / Navigation / RegularArrow / Medium / Left / CenterAlign Copy</title>
    <g id="Slider-Gallery-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="slider-gallery---arrows" transform="translate(-1101.000000, -247.000000)" className={className}>
        <g id="🎨-Color" transform="translate(1095.000000, 247.000000)">
          <polygon
            transform="translate(12.000000, 12.000000) scale(1, -1) rotate(90.000000) translate(-12.000000, -12.000000) "
            points="11.9989984 6 0 16.4818792 1.31408063 18 11.9989984 8.66778523 22.6859194 18 24 16.4818792"></polygon>
        </g>
      </g>
    </g>
  </svg>
);
NavArrowRight.displayName = 'NavArrowRight';
/* tslint:enable */
/* eslint-enable */
