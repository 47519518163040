import {getWrappedComponent} from '../../../appConfig';
import {SliderGallery} from '../../../sliderGallery/components/SliderGallery/SliderGallery';
import {sliderGlobalPropsStrategy} from '../../../sliderGallery/sliderGlobalStrategy';
import styleParams from '../stylesParams';

export default getWrappedComponent({
  Component: SliderGallery,
  stylesParams: styleParams,
  globalPropsStrategy: sliderGlobalPropsStrategy,
  sliderGallery: true,
});
