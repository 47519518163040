import React from 'react';
import type {IProduct} from '../../../../types/galleryTypes';
import {ProductType} from '../../../../types/galleryTypes';
import a11y from '@wix/wixstores-client-core/dist/es/src/assets/styles/_accessibility.scss';
import s from './ProductPrice.scss';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../../globalPropsContext';
import {BasePrice} from '@wix/wixstores-client-common-components/dist/es/src/BasePrice/BasePrice';
import {PriceBreakdown} from '@wix/wixstores-client-common-components/dist/es/src/PriceBreakdown/PriceBreakdown';
import {BaseGalleryStore} from '../../../../viewerScript/BaseGalleryStore';
import {IGalleryGlobalProps} from '../../../../gallery/galleryGlobalStrategy';
import {
  formattedPrimaryPrice,
  formattedSecondaryPrice,
  hasAnyDiscount,
  hasAutomaticDiscount,
} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';
import {ConditionalRender} from '../../../../category/components/ConditionalRender/ConditionalRender';

export enum DataHook {
  SrPriceBeforeDiscount = 'sr-product-item-price-before-discount',
  SrPriceToPay = 'sr-product-item-price-to-pay',
  SrPriceRange = 'st-price-range',
  OutOfStock = 'product-item-out-of-stock',
  PriceBeforeDiscount = 'product-item-price-before-discount',
  PriceToPay = 'product-item-price-to-pay',
  PriceRange = 'price-range-from',
  BasePriceComponent = 'base-price-component',
}

interface TextMapProps {
  productPriceBeforeDiscountSR: string;
  productOutOfStockText: string;
  productPriceAfterDiscountSR: string;
  productPriceWhenThereIsNoDiscountSR: string;
  measurementUnits?: {[key: string]: {[key: string]: string}};
  pricePerUnitSR?: string;
}

export interface IProductPriceProps extends IGalleryGlobalProps {
  product: IProduct;
  allowFreeProducts: boolean;
  textsMap: TextMapProps;
  fromPrice?: string;
  priceBreakdown: BaseGalleryStore['priceBreakdown'];
  sendClickShippingInfoLinkSf: Function;
  isRTL: boolean;
  shouldUseCommonDiscountPricingMethods: boolean;
}

const getPriceBeforeDiscount = ({textsMap, secondaryPrice}: {textsMap: TextMapProps; secondaryPrice: string}) => {
  return (
    <>
      <span className={a11y.srOnly} data-hook={DataHook.SrPriceBeforeDiscount}>
        {textsMap.productPriceBeforeDiscountSR}
      </span>
      <span
        data-hook={DataHook.PriceBeforeDiscount}
        className={s.priceBeforeDiscount}
        data-wix-original-price={secondaryPrice}>
        {secondaryPrice}
      </span>
    </>
  );
};

class PriceRangeComp extends React.Component<
  {formattedFromPrice: string; textsMap: TextMapProps} & IProvidedTranslationProps
> {
  public render() {
    const {formattedFromPrice, textsMap, t} = this.props;
    return (
      <>
        <span className={a11y.srOnly} data-hook={DataHook.SrPriceRange}>
          {textsMap.productPriceWhenThereIsNoDiscountSR}
        </span>
        <span data-hook={DataHook.PriceRange} className={s.priceFrom}>
          {t('priceRangeText', {formattedAmount: formattedFromPrice})}
        </span>
      </>
    );
  }
}

const PriceRange = withGlobals(withTranslations()(PriceRangeComp));

const RegularPrice = ({
  hasDiscount,
  product,
  textsMap,
  shouldUseCommonDiscountPricingMethods,
}: {
  hasDiscount: boolean;
  product: IProduct;
  textsMap: TextMapProps;
  shouldUseCommonDiscountPricingMethods: boolean;
}) => {
  const primaryPrice = getPrimaryPrice({
    product,
    hasDiscount,
    shouldUseCommonDiscountPricingMethods,
  });

  return (
    <>
      {hasDiscount &&
        getSecondaryPrice({
          product,
          textsMap,
          shouldUseCommonDiscountPricingMethods,
        })}
      {
        <>
          <span className={a11y.srOnly} data-hook={DataHook.SrPriceToPay}>
            {hasDiscount ? textsMap.productPriceAfterDiscountSR : textsMap.productPriceWhenThereIsNoDiscountSR}
          </span>
          <span data-hook={DataHook.PriceToPay} className={s.priceToPay} data-wix-price={primaryPrice}>
            {primaryPrice}
          </span>
        </>
      }
    </>
  );
};

function getSecondaryPrice({
  product,
  textsMap,
  shouldUseCommonDiscountPricingMethods,
}: {
  product: IProduct;
  textsMap: TextMapProps;
  shouldUseCommonDiscountPricingMethods: boolean;
}) {
  if (shouldUseCommonDiscountPricingMethods) {
    const selectedSecondaryPrice = formattedSecondaryPrice(product);
    return getPriceBeforeDiscount({
      textsMap,
      secondaryPrice: selectedSecondaryPrice,
    });
  } else {
    const isDiscountsExist =
      Boolean(product.itemDiscount?.priceAfterDiscount) && Boolean(product.formattedComparePrice);
    const selectedSecondaryPrice = isDiscountsExist ? product.formattedComparePrice : product.formattedPrice;

    return getPriceBeforeDiscount({
      textsMap,
      secondaryPrice: selectedSecondaryPrice,
    });
  }
}

function getPrimaryPrice({
  product,
  hasDiscount,
  shouldUseCommonDiscountPricingMethods,
}: {
  product: IProduct;
  hasDiscount: boolean;
  shouldUseCommonDiscountPricingMethods: boolean;
}) {
  if (shouldUseCommonDiscountPricingMethods) {
    const itemDiscount = product.itemDiscount;
    hasAutomaticDiscount(product);
    return formattedPrimaryPrice({...product, itemDiscount});
  } else {
    const discountPrice = product.itemDiscount?.priceAfterDiscount || product.formattedComparePrice;

    return hasDiscount ? discountPrice : product.formattedPrice;
  }
}

const BasePriceContainer = ({product, textsMap}) => {
  const getBasePriceTranslationSR = (translation, vars) => {
    //eslint-disable-next-line prefer-named-capture-group
    return translation.replace(/\{\{([^}]+)\}\}/gi, (_match, k) => {
      return vars[k.trim()];
    });
  };

  const {
    formattedPricePerUnit,
    pricePerUnitData: {baseQuantity, baseMeasurementUnit},
  } = product;

  const noun = baseQuantity === 1 ? 'singular' : 'plural';
  const unitTranslation = textsMap.measurementUnits[baseMeasurementUnit].abbr;
  const screenReaderText = getBasePriceTranslationSR(textsMap.pricePerUnitSR, {
    basePrice: formattedPricePerUnit,
    units: `${baseQuantity} ${textsMap.measurementUnits[baseMeasurementUnit][noun]}`,
  });

  return (
    <BasePrice
      data-hook={DataHook.BasePriceComponent}
      className={s.basePrice}
      formattedPricePerUnit={formattedPricePerUnit}
      baseQuantity={baseQuantity}
      unitTranslation={unitTranslation}
      screenReaderText={screenReaderText}
      withRTLSupport
    />
  );
};

const BasePriceWithGlobals = withGlobals(BasePriceContainer);

export class ProductPrice extends React.Component<IProductPriceProps, any> {
  private readonly sendClickShippingInfoLinkSfEvent = () => {
    const {product, sendClickShippingInfoLinkSf} = this.props;
    sendClickShippingInfoLinkSf(product.id);
  };

  private hasDiscount({
    shouldUseCommonDiscountPricingMethods,
  }: {
    shouldUseCommonDiscountPricingMethods: boolean;
  }): boolean {
    const {product} = this.props;
    if (shouldUseCommonDiscountPricingMethods) {
      return hasAnyDiscount(product);
    }
    return product.discount?.value > 0 || hasAutomaticDiscount(product);
  }

  private renderPrice() {
    const {product, textsMap, fromPrice, isRTL, shouldUseCommonDiscountPricingMethods} = this.props;
    const {shouldRenderTaxDisclaimer, taxDisclaimer, shippingDisclaimer} = this.props.priceBreakdown;

    const hasDiscount = this.hasDiscount({shouldUseCommonDiscountPricingMethods});

    const shouldRenderBasePrice = !!product.formattedPricePerUnit;

    return (
      <>
        <div className={s.prices}>
          {fromPrice ? (
            <PriceRange formattedFromPrice={fromPrice} textsMap={textsMap} />
          ) : (
            <RegularPrice
              shouldUseCommonDiscountPricingMethods={shouldUseCommonDiscountPricingMethods}
              hasDiscount={hasDiscount}
              product={product}
              textsMap={textsMap}
            />
          )}
        </div>
        {shouldRenderBasePrice && <BasePriceWithGlobals product={product} textsMap={textsMap} />}
        {(shouldRenderTaxDisclaimer || shippingDisclaimer?.show) && (
          <PriceBreakdown
            shouldRenderTaxDisclaimer={shouldRenderTaxDisclaimer}
            taxDisclaimerLabel={taxDisclaimer}
            shippingDisclaimer={shippingDisclaimer}
            isDigitalProduct={product.productType === ProductType.DIGITAL}
            whenShippingDisclaimerDialogOpen={this.sendClickShippingInfoLinkSfEvent}
            isRTL={isRTL}
            className={s.priceBreakdown}
          />
        )}
      </>
    );
  }

  public render() {
    const {product, allowFreeProducts, textsMap, shouldUseCommonDiscountPricingMethods} = this.props;
    const isOutOfStock = !product.isInStock;
    const shouldRenderPrices = product.price !== 0 || allowFreeProducts;
    const hasDiscount = this.hasDiscount({shouldUseCommonDiscountPricingMethods});

    const noRenderWhenDiscountedToZero = !(
      !allowFreeProducts &&
      product.comparePrice === 0 &&
      !hasAutomaticDiscount(product) &&
      hasDiscount
    );

    const isPreOrderItem = product.inventory.availableForPreOrder;
    const shouldRenderOutOfStock = isOutOfStock && !isPreOrderItem;

    return (
      <>
        {shouldRenderOutOfStock && (
          <>
            <ConditionalRender by={'notShowAddToCartButton'}>
              <span data-hook={DataHook.OutOfStock} className={s.outOfStock}>
                {textsMap.productOutOfStockText}
              </span>
            </ConditionalRender>
            <ConditionalRender by={'showAddToCartButton'} className={s.outOfStockPriceContainer}>
              {this.renderPrice()}
            </ConditionalRender>
          </>
        )}

        {!shouldRenderOutOfStock && shouldRenderPrices && noRenderWhenDiscountedToZero && this.renderPrice()}
      </>
    );
  }
}
